/* eslint-disable no-multi-assign */
<template lang="pug">
//- v-card.calc(flat Zoutlined Zclass="$vuetify.breakpoint.lgAndUp ? 'calc' : 'fixed-calc'")
t-window(v-bind="$attrs", shaped, v-on="$listeners", min-height="200px" color="dark")
  template(slot="title") Калькулятор
  template(slot="header-container")
    v-card(flat)
      v-card-title.justify-space-between
        //- v-btn(text rounded @click="$emit('showForm')") {{ $t("смена") }}
        v-btn(text rounded @click="$emit('route', 'cart', true)") {{ $t("позиции") }}
        div
          v-btn.ml-4(color="grey darken-3" fab depressed x-small @click="$emit('input', false)")
            v-icon(color="white") mdi-close
          v-icon.ml-2(color="white", @click="$emit('fullscreen')") mdi-dots-vertical
      v-card-actions.mt-6.mr-2.justify-end.display-3
        .noselect.ezcar {{ display }}

  template(slot="footer")
    Basket(
      @click="$emit('route', 'cart', true)",
      no-menu,
      :total="display"
      emptyLabel="Позиции",
    )

  v-container.py-0.mb-3
    v-row.numpad.wrap.headline.px-3(Zno-gutters)
      v-flex(xs4): v-btn(block depressed @click="press(1)") 1
      v-flex(xs4): v-btn(block depressed @click="press(2)") 2
      v-flex(xs4): v-btn(block depressed @click="press(3)") 3
      //- v-flex(xs4): v-btn(block depressed @click="press(1)") X
      v-flex(xs4): v-btn(block depressed @click="press(4)") 4
      v-flex(xs4): v-btn(block depressed @click="press(5)") 5
      v-flex(xs4): v-btn(block depressed @click="press(6)") 6
      //- v-flex(xs4): v-btn(block depressed @click="press(1)") X
      v-flex(xs4): v-btn(block depressed @click="press(7)") 7
      v-flex(xs4): v-btn(block depressed @click="press(8)") 8
      v-flex(xs4): v-btn(block depressed @click="press(9)") 9
      //- v-flex(xs4): v-card): v-card-text {{ $t("далее" @click="press(1)") }}
      v-flex(xs4): v-btn(block depressed @click="addItemCalc()"): v-icon(color="primary") mdi-plus
      v-flex(xs4): v-btn(block depressed @click="press(0)") 0
      v-flex(xs4): v-btn(block depressed @click="backspace()"): v-icon(color="primary") mdi-backspace
      //- v-flex(xs3): v-card(outlined height="100"): v-card-text

  //- div.d-flex(style="flex: 8" class="numpad")
    div.d-flex.flex-column(style="flex: 3")
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('7')") 7
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('8')") 8
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('9')") 9
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('4')") 4
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('5')") 5
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('6')") 6
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('1')") 1
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('2')") 2
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('3')") 3
      div.d-flex(style="flex: 1")
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="plus()") &plus;
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="digit('0')") 0
        div(style="flex: 1; display: flex;")
          v-btn.pa-0.headline(depressed @click="backspace()")
            v-icon mdi-backspace
    div.d-flex.flex-column(style="flex: 1")
      div(style="flex: 1; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="secondary" @click="toggleCashPayment()") {{ cashPayment ? $t("наличные_short") : $t("карта") }}
      div(style="flex: 3; display: flex;")
        v-btn.pa-0.headline.text-capitalize(style="font-size: 1.4rem !important" depressed color="primary" @click="next()") {{ $t("далее") }}
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Basket from '../cart/Basket.vue'

export default {
  components: {
    Basket,
  },

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    amount: {
      type: Number,
    },
  },

  data: () => ({
    input: '',
    cashPayment: 'Наличные',
    item: {
      name: null,
      price: 0,
      realQuantity: 1,
      unitType: 'PIECE',
      discountValue: 0,
      markupValue: 0,
      markupType: 'шт',
      markupPercentage: 1,
      total: 0,
    },
  }),

  computed: {
    display() {
      return this.input ? Number(this.input) : 0
    },
  },

  watch: {},

  methods: {
    ...mapActions({
      addItem: 'tjtheme/addItem',
    }),
    toggleCashPayment() {},
    press(d) {
      this.input += d
    },
    backspace() {
      this.input = this.input.slice(0, -1)
    },
    plus() {},
    addItemCalc() {
      // eslint-disable-next-line no-multi-assign
      this.item.price = this.item.total = Number(this.display)
      this.addItem({ ...this.item })
      Object.assign(this.$data, this.$options.data())
    },
  },
}
</script>
<style lang="stylus">

// .theme--dark.v-btn.v-btn--has-bg
//   background-color: #373740

.numpad
  .v-btn
    min-height: 96px
    font-size: inherit
    border 1px solid  #373740 !important

</style>
