/* eslint-disable import/no-unresolved */
<template lang="pug">
//- t-dialog(:value="value" @input="$emit('input',$event); tab = 'form'" title="Молоко")
t-window(v-bind="$attrs", v-on="$listeners", min-height="300px")
  v-window(v-model="tab", continuous)
    v-window-item(value="form"): Form(@route="tab = 'calc'")
    v-window-item(value="calc"): Calc(@route="tab = 'form'")
</template>
<script>
import { mapState, mapActions } from 'vuex'

import Basket from 'T@/components/cart/Basket.vue'
import Form from 'T@/components/catalog/Form.vue'
import Calc from 'T@/components/catalog/Calc.vue'

export default {
  components: {
    Form,
    Calc,
    Basket,
  },
  data: () => ({
    tab: 'form',
    item: 0,
  }),
  methods: {
  },
}
</script>
<style lang="stylus"></style>
